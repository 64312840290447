/**
 * components/_anime.scss
 */

.js-anime {

  &.-anime {
    transition: transform .4s ease, opacity .2s;
    transform: none;
    opacity: 1;

    @media (min-width: 992px) {

      &-1 {
        transition-delay: 0s;
      }

      &-2 {
        transition-delay: .2s;
      }

      &-3 {
        transition-delay: .4s;
      }

      &-4 {
        transition-delay: .6s;
      }

      &-5 {
        transition-delay: .8s;
      }

      &-6 {
        transition-delay: 1s;
      }
    }
  }

  &.-motion {
    transform: translateY(5rem);
  }
}