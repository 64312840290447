/**
 * components/_hero.scss
 */

.hero {

  .logo {

    @media (max-width: 991px) {
      order: 1;
    }
  }

  .title {

    @media (max-width: 991px) {
      order: 2;
    }
  }

  .text {

    @media (max-width: 991px) {
      order: 4;
    }
  }

  .button {

    @media (max-width: 991px) {
      order: 3;
    }
  }
}