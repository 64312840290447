/**
 * components/_form.scss
 */

 .form {

  &.-hide {
    display: none;
  }

  &.-field {
    position: relative;
    margin-bottom: 1.875rem;
    padding: 0;

    &.-error {

      input:not([type='submit']), select, textarea {
        border-color: #e00065;
      }
    }

    &-1 {
      z-index: 5;
    }

    &-2 {
      z-index: 4;
    }

    &-3 {
      z-index: 3;
    }
  }

  &.-group {
    position: relative;

    &.-double-inline {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      flex-flow: column;
      @media (min-width: 992px) {
        flex-wrap: nowrap;
        flex-flow: row;
      }

      .checkbox {
        min-width: 100%;
        @media (min-width: 992px) {
          min-width: 45%;
        }
      }
    }

    &.-center {
      text-align: center;
    }

    &.-center-col {
      text-align: center;
      display: flex;
      flex-flow: column;
      flex-wrap: wrap;

      a {
        margin-top: 0.7rem;
      }
    }

    &.-gray {

      input:not([type='submit']), textarea, select {
        background: var(--colorLight);
      }
    }

    & > input:not([type='submit']), 
    textarea, 
    select 
    /*input:not([autocapitalize='none']),
    input:not([autocomplete='off']),
    input:not([spellcheck='false']) */
    {
      width: 100%;
      display: block;
      padding-left: 1rem;
      padding-right: 1rem;
      background: var(--colorInverse);
      border: 1px solid var(--colorInverse);
      border-radius: 5px;
      text-align: left;
      color: #000000;
      font-size: .9rem;
      letter-spacing: .03em;
      outline: none;
      position: relative;
      z-index: 2;

      @media (min-width: 992px) {
        font-size: .9rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        letter-spacing: .02em;
      }
    }

    & > input:not([type='submit']),
    textarea,
    select {
      padding-top: 0;
      padding-bottom: 0;
      height: 3.5rem;
    }

    & > input[type=text], 
    & > input[type=email], 
    & > input[type=tel], 
    & > input[type=number], 
    & > input[type=date], 
    & > input[type=time], 
    textarea {
      width: 100%;

      &::-webkit-input-placeholder {
        color: inherit;
        opacity: .4;
      }

      &:-ms-input-placeholder {
        color: inherit;
        opacity: .4;
      }

      &::placeholder {
        color: inherit;
        opacity: .4;
      }
    }

    textarea {
      resize: vertical;
      padding-top: .8rem;
      height: 12.45rem;

      @media (min-width: 992px) {
        height: 11.15rem;
      }
    }
  }

  &.-check {

    &-input {
      box-sizing: border-box;
      padding: 0;
      margin-top: .3rem;
      margin-left: -1.25rem;
      opacity: 0;
      position: absolute;
      overflow: visible;
      visibility: hidden;
  
      &:checked + .-check-label:after {
        background-color: var(--colorLight);
        opacity: 1;
        margin-top: 0;
        line-height: 1.6;
      }
    }
  
    &-label {
      display: inline-block;
      position: relative;
      padding-left: 1.675rem;
      padding-top: 5px;
      min-height: 30px;
      font-weight: 400;
      line-height: 20px;
      font-size: .875rem;
      margin-bottom: 0;
      transition: all 0.2s ease;
  
      &:before, &:after {
        content: " ";
        display: inline-block;
        position: absolute;
        width: 15px;
        height: 15px;
        left: 0;
        top: 7px;
        border: 1px solid #b1bcce;
        transition: all 0.2s ease;
        border-radius: .1rem;
      }
  
      &:before {
        background-color: #ffffff;
      }
  
      &:after {
        top: 7px;
        text-align: center;
        opacity: 0;
        font-weight: 900;
        border: 0;
        background-color: inherit;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 10px auto;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg enable-background='new 0 0 78.369 78.369' viewBox='0 0 78.369 78.369' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m78.049 19.015-48.591 48.591c-.428.428-1.121.428-1.548 0l-27.59-27.591c-.427-.426-.427-1.119 0-1.547l6.704-6.704c.428-.427 1.121-.427 1.548 0l20.113 20.112 41.113-41.113c.429-.427 1.12-.427 1.548 0l6.703 6.704c.428.427.428 1.119 0 1.548z' fill='%230648B3'/%3E%3C/svg%3E");
      }
    }
  }

  &.-message {

    &.-error {
      width: 100%;
      padding-left: .5rem;
      padding-right: .5rem;
      padding-top: 3px;
      padding-bottom: 2px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      font-size: 11px;
      line-height: 1.4;
      color: #e00065;
      display: block;
      position: absolute;
      left: 0;
      top: calc(3.5rem - 3px);
      z-index: 1;

      @media (min-width: 992px) {
        padding-top: 4px;
        font-size: 12px;
        line-height: 1.3;
        padding-left: 1.5rem;
        padding-right: 1rem;
      }
    }

    &.-contact-error {
      width: 100%;
      padding-left: .5rem;
      padding-right: .5rem;
      padding-top: 3px;
      padding-bottom: 2px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      font-size: 11px;
      line-height: 1.4;
      color: #ffffff;
      background-color: #e00065;
      display: block;
      position: absolute;
      left: 0;
      top: calc(3.5rem - 3px);
      z-index: 1;

      @media (min-width: 992px) {
        padding-top: 4px;
        font-size: 12px;
        line-height: 1.3;
        padding-left: 1.5rem;
        padding-right: 1rem;
      }

      &.-area {
        top: calc(12.4rem - 3px);
        @media (min-width: 992px) {
          top: calc(11.1rem - 3px);
        }
      }
    }
  }

  &.-link {
    text-decoration: none;
    font-size: .9rem;
    color: #000000;
    opacity: 1;
    transition: opacity .35s;

    &:hover {
      opacity: .7;
    }

    @media (min-width: 992px) {
      font-size: 1rem;
    }
  }

  label {
    display: block;
    width: 100%;
    margin: 0 0 2px;
    padding: 0;
    outline: none;
    font-weight: 500;
    font-size: .85rem;
    color: var(--colorDark);

    @media (min-width: 992px) {
      font-size: .9rem;
    }

    &.-contact {
      color: var(--colorLight);
    }
  }
  

  &.-file {
    position: relative;

    input[type="file"] {
      width: 100%;
      height: 100%;
      opacity: 0;
      overflow: hidden;
      visibility: visible;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      cursor: pointer;
    }

    &-inner {
      width: 100%;
      height: 3.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 1rem;
      padding-right: 1rem;
      background: var(--colorLight);
      border: 1px solid var(--colorInverse);
      border-radius: 5px;
      text-align: left;
      color: #000000;
      font-size: .9rem;
      letter-spacing: .03em;
      outline: none;
      position: relative;
      z-index: 1;
      cursor: pointer;

      @media (min-width: 992px) {
        font-size: .9rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        letter-spacing: .02em;
      }

      i {
        display: block;
        width: 30px;
        
        svg {
          width: 100%;
          height: auto;
        }
      }

      &.-ok {
        background: var(--colorSupport);
        color: var(--colorInverse);
      }
    }
  }

  &.-select {
    width: 100%;
    display: block;
    position: relative;
    z-index: 3;

    &-item__control {
      background: var(--colorLight);
      border: 1px solid var(--colorInverse);
      border-radius: 5px;
      min-height: 3.5rem;
      width: 100%;
      font-size: .9rem;
      letter-spacing: .03em;
      position: relative;
      z-index: 5;

      &:hover {
        border-color: var(--colorInverse);
      }
  
      /*
      padding-right: 1rem;
      color: #000000;
      */
    }

    &-item__control--is-focused {
      box-shadow: none;
    }

    &-item__menu {
      z-index: 9;
    }

    &-item__value-container {
      padding-left: 1rem;

      @media (min-width: 992px) {
        padding-left: 1.5rem;
      }
    }

    &-item__placeholder {
      color: #000000;
      opacity: .4;
    }

    &-item__multi-value {
      background: none;
    }

    &-item__multi-value__label {
      background-color: var(--colorDark);
      color: var(--colorInverse);
      padding-left: 10px;
      padding-right: 10px;
      min-height: 22px;
      font-size: .7rem;
      font-weight: 400;
      border-radius: 0;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      letter-spacing: .02em;
      display: flex;
      align-items: center;
      white-space: normal;

      @media (min-width: 992px) {
        min-height: 29px;
        padding-left: 18px;
        padding-right: 18px;
      }
    }

    &-item__multi-value__remove {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      padding-right: 8px;
      background-color: var(--colorDark);

      svg, svg path {
        fill: var(--colorInverse);
      }

      &:hover {

        svg, svg path {
          fill: var(--colorDark);
        }
      }
    }
  }

  .hide {
    opacity: 0;
    height: 0;
  }

  .show {
    opacity: 1;
  }

  .success {
    display: block;
    padding: 10px;

    div {
      height: auto;
    }

    span{
      display: block;
      color: var(--colorPrimary);
    }

  }
}


.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all .5s ease-out;

  div {
    position: relative;
    width: auto;
    height: auto;
  }

  &.-off {
    display: none;
  }
}