/**
 * components/_register.scss
 */

.register {

  &.-header {
    background-color: var(--colorQuaternary);
    padding-top: 6.7rem;
    padding-bottom: 2.5rem;

    @media (min-width: 668px) {
      padding-top: 10rem;
      padding-bottom: 2.85rem;
    }

    @media (min-width: 992px) {
      padding-top: 12.5rem;
      padding-bottom: 3.125rem;
    }

    @media (min-width: 1600px) {
      padding-top: 10rem;
      padding-bottom: 2.5rem;
    }

    &-cnt {

      @media (min-width: 668px) {
        max-width: 52%;
      }
    }

    .container {
      display: flex;
      flex-flow: column;

      @media (min-width: 668px) {
        justify-content: space-between;
        align-items: center;
        flex-flow: row;
      }
    }
  }

  &.-profile {
    display: flex;
    flex-flow: row;
    align-items: flex-start;
  }

  &.-image {
    position: relative;
    flex: 0 0 60px;
    max-width: 60px;
    margin-right: 20px;

    @media (min-width: 992px) {
      flex: 0 0 100px;
      max-width: 100px;
      margin-right: 38px;
    }

    .image-container {
      max-width: 100%;
      border-radius: 100%;
      width: 60px;
      height: 60px;
      background-size: cover;

      @media (min-width: 992px) {
        width: 100px;
        height: 100px;
        margin-right: 20px;
      }
    }

    img {
      max-width: 100%;
      border-radius: 100%;
      background: var(--colorLight);
    }
  }

  &.-flag {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: var(--colorTertiary);
    background-repeat: no-repeat;
    display: block;
    text-indent: -9999px;

    @media (min-width: 992px) {
      width: 29px;
      height: 29px;
      bottom: 2px;
      right: 2px;
    }

    &.favor {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 13.333 11.667' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m-10.391 7.13a4.986 4.986 0 0 1 -1.667-3.509 3.01 3.01 0 0 1 3.2-3.18c1.8 0 2.413.834 3.465 2.04 1.054-1.206 1.668-2.04 3.466-2.04a3.01 3.01 0 0 1 3.2 3.18 4.985 4.985 0 0 1 -1.664 3.509l-5 4.978z' fill='%23FFFFFF' transform='translate(12.058 -.441)'/%3E%3C/svg%3E");
      background-size: 10px auto;
      background-position: center 55%;

      @media (min-width: 992px) {
        background-size: 17px auto;
      }
    }

    &.payment {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 9.115 15.428' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11.02-39.211c0-2.176-1.906-2.7-3.684-3.144v-3.7a5.724 5.724 0 0 1 2.7.889l.524-1.271a6.373 6.373 0 0 0 -3.224-.969v-1.794h-1.017v1.81c-2.462.206-3.589 1.572-3.589 3.144 0 2.16 1.842 2.716 3.589 3.144v3.684a5.539 5.539 0 0 1 -3.272-1.271l-.587 1.241a6.424 6.424 0 0 0 3.859 1.382v1.795h1.017v-1.795c2.509-.207 3.684-1.572 3.684-3.145zm-6.734-5.1c0-.842.619-1.556 2.033-1.731v3.43c-1.143-.299-2.033-.712-2.033-1.697zm3.049 6.877v-3.414c1.175.318 2.128.715 2.128 1.731.001.859-.65 1.542-2.127 1.685z' fill='%23FFFFFF' stroke='%23FFFFFF' stroke-width='.5' transform='translate(-2.155 49.45)'/%3E%3C/svg%3E");
      background-size: 7px auto;
      background-position: center;

      @media (min-width: 992px) {
        background-size: 10px auto;
      }
    }
  }

  &.-info {
    flex: auto;
  }

  &.-name {
    font-size: 1.275rem;
    line-height: 1.375rem;
    letter-spacing: 0;
    font-weight: 600;
    color: var(--colorInverse);
    word-break: keep-all;

    @media (min-width: 992px) {
      margin-top: 1rem;
      font-size: 1.875rem;
      line-height: 1.875rem;
      letter-spacing: -.0125em;
    }

    @media (min-width: 1600px) {
      white-space: nowrap;
      font-size: 1.5rem;
      line-height: 1.5rem;
      margin-top: .8rem;
    }
  }

  &.-details {
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    align-items: flex-start;
    margin-top: .8rem;

    @media (min-width: 992px) {
      flex-flow: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      margin-top: 1.3rem;
    }

    @media (min-width: 1600px) {
      margin-top: 1.05rem;
    }

    .trade-divider {
      padding: 0 calc(0.7rem) 0 calc(0.7rem - 10px);

      display: none;
      @media (min-width: 992px) {
        display: inline;
      }
    }
  }

  &.-title {
    background-color: rgba(255,255,255,.3);
    color: var(--colorInverse);
    padding: 0 10px;
    min-width: 100px;
    height: 22px;
    font-size: .7rem;
    font-weight: 400;
    line-height: 1;
    border-radius: 20px;
    letter-spacing: .02em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;

    &.-profile-complete {
      background-color: rgba(162, 204, 58, 1);
      svg {
        margin: 0 3px;
      }
    }

    @media (min-width: 668px) {
      margin-right: 10px;
    }

    @media (max-width: 991px) {
      margin-bottom: 8px;
    }

    @media (min-width: 992px) {
      height: 29px;
      font-size: 1rem;
      padding: 0 18px;
      min-width: 120px;
    }

    @media (min-width: 1600px) {
      font-size: .8rem;
    }

    .vnetwork {
      position: relative;
      margin-top: 4px;
      margin-right: 4px;
      background-color: var(--colorTertiary);
      color: #ffffff;

      height: 22px;
      width: 22px;
      margin-left: -28px;
      @media (min-width: 992px) {
        height: 29px;
        width: 29px;
        margin-left: -18px;
      }
      
    }
  }

  &.-fans {

    &.-wrap {
      display: flex;
      flex-flow: row;
      align-items: center;
    }

    &.-item {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      border: 2px solid var(--colorQuaternary);
      z-index: 3;

      @media (min-width: 992px) {
        width: 34px;
        height: 34px;
        border-width: 3px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }

      &:nth-of-type(2) {
        z-index: 2;
        margin-left: -7px;

        @media (min-width: 992px) {
          margin-left: -10px;
        }
      }

      &:nth-of-type(3) {
        z-index: 1;
        margin-left: -7px;

        @media (min-width: 992px) {
          margin-left: -10px;
        }
      }
    }

    &.-likes {
      padding-left: 6px;
      display: block;
      font-size: .7rem;
      color: var(--colorInverse);

      @media (min-width: 992px) {
        padding-left: 10px;
        font-size: 1rem;
      }

      @media (min-width: 1600px) {
        font-size: .8rem;
      }
    }
  }

  &.-abstract {
    font-size: .85rem;
    line-height: 1.375rem;
    color: var(--colorInverse);
    margin-top: 1.2rem;

    @media (min-width: 992px) {
      padding-left: 138px;
      font-size: 1.25rem;
      line-height: 1.875rem;
    }

    @media (min-width: 1600px) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .share {
    
    &.-wrap {

      @media (max-width: 667px) {
        margin-top: 2rem;
      }
    }
  }
}