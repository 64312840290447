/**
 * components/_typography.scss
 */

:root {
  font-size: 16px;

  @media (max-width: 339px) and (orientation: portrait) {
    font-size: 13px;
  }

  @media (max-height: 666px), (max-width: 340px) {
    font-size: 14px;
  }

  @media (min-width: 667px) and (orientation: landscape) {
    font-size: 15px;
  }

  @media (min-width: 992px) {
    font-size: 16px;
  }

  @media (min-width: 1600px) {
    font-size: 20px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.title, .text {
  font-style: normal;
  font-style: normal;
  word-break: normal;

  &.-semi {
    font-weight: 600;
  }

  &.-strong {
    font-weight: 700;
  }
}

.title {
  letter-spacing: -.0125em;
  font-weight: 500;

  &.-larger {
    font-size: 1.805rem;
    line-height: 2.5rem;
    word-spacing: -.02em;

    @media (min-width: 992px) {
      font-size: 3rem;
      line-height: 4rem;
    }
  }

  &.-median {
    font-size: 1.75rem;
    line-height: 2.45rem;

    @media (min-width: 992px) {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }

  &.-smaller {
    font-size: 1.25rem;
    line-height: 1.56rem;

    @media (max-width: 991px) {

      &-sm {
        font-size: 1.25rem;
        line-height: 1.875rem;
        margin-top: 1.8rem;
        display: block;

        br {
          display: none;
        }
      }
    }

    @media (min-width: 992px) {
      font-size: 1.625rem;
      line-height: 2.06rem;
    }
  
    @media (min-width: 1600px) {
      font-size: 1.3rem;
      line-height: 1.72rem;
    }
  }

  &.-chapter {
    font-size: .875rem;
    line-height: 1.125rem;
    font-weight: 600;

    @media (min-width: 992px) {
      font-size: 1rem;
      line-height: 1.375rem;
    }
  }

  &.-regular {
    font-weight: 400;
  }

  &.-condensed {
    line-height: 1.25;
  }
}

.text {
  letter-spacing: .001em;
  font-size: 1rem;
  font-weight: 400;

  &.-larger {
    font-size: 1.125rem;
    line-height: 1.75rem;

    @media (min-width: 992px) {
      font-size: 1.3rem;
      line-height: 2.25rem;
      letter-spacing: -.00625em;
    }
  }

  &.-median {
    font-size: 1.125rem;
    line-height: 1.95rem;

    @media (min-width: 992px) {
      font-size: 1.1rem;
      line-height: 1.925rem;
      letter-spacing: -.00625em;
    }
  }

  &.-smaller {
    font-size: 1rem;
    line-height: 1.875rem;

    @media (min-width: 992px) {
      font-size: 1rem;
      line-height: 2rem;

      &-sm {
        font-size: 1.25rem;
        line-height: 1.875rem;
      }
    }
  }

  &.-tiny {
    font-size: .8rem;
    line-height: 1.5;
    letter-spacing: .005em;

    @media (min-width: 1600px) {
      font-size: .7rem;
    }
  }
}

.center {
  text-align: center;
}
