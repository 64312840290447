/**
 * objects/_smile.scss
 */

.smile {

  &.-wrap {
    position: absolute;
    width: 30%;
    height: 0;
    padding-top: 30%;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-delay: 3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-name: smiling;
  }

  &.-element {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &.-circle {
    position: absolute;
    width: 100%;
    height: 100%;
    animation-duration: 2.5s;
    animation-iteration-count: 1;
    animation-delay: 3.2s;
    animation-timing-function: ease;
  }

  &-1 {
    top: 11%;
    left: -3%;

    .-circle {
      transform: rotate(-45deg);
      animation-name: rotation-1;
    }
  }

  &-2 {
    top: -3%;
    right: -2%;

    .-circle {
      transform: rotate(45deg);
      animation-name: rotation-2;
    }
  }

  &-3 {
    bottom: -3%;
    left: 34%;

    .-circle {
      transform: rotate(180deg);
      animation-name: rotation-3;
    }
  }
}

@keyframes smiling {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rotation-1 {

  0% {
    transform: rotate(-45deg);
    opacity: 0;
  }

  15% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  100% {
    transform: rotate(314deg);
    opacity: 1;
  }
}

@keyframes rotation-2 {

  0% {
    transform: rotate(45deg);
    opacity: 0;
  }

  15% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  100% {
    transform: rotate(404deg);
    opacity: 1;
  }
}

@keyframes rotation-3 {

  0% {
    transform: rotate(180deg);
    opacity: 0;
  }

  15% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  100% {
    transform: rotate(539deg);
    opacity: 1;
  }
}