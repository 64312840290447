/**
 * components/_banner.scss
 */
 
.banner {

  &.-exchange {

    &.-content {
      max-width: 675px;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: 667px) {
        overflow: hidden;
        margin-left: -36px;
        margin-right: -36px;
        justify-content: center;
      }
    }

    &.-item {
      width: 260px;
      max-width: 100%;
      min-height: 390px;

      @media (max-width: 667px) {
        flex: 0 0 188px;
      }

      @media (min-width: 992px) {
        min-height: 480px;
      }

      .-image {
        width: 100px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.-symbol {
      width: 1.2rem;
      flex: 0 0 1.2rem;
      max-width: 1.2rem;
      position: relative;
      margin-top: 4rem;

      @media (min-width: 992px) {
        width: 2rem;
        flex: 0 0 2rem;
        max-width: 2rem;
      }
    
      i {
        background-color: var(--colorTertiary);
        width: 65px;
        height: 65px;
        padding: 10px;
        border-radius: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        @media (min-width: 992px) {
          width: 80px;
          height: 80px;
        }
      }

      svg {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }
}