/**
 * components/_button.scss
 */
 
.button {

  &.-link, &.-register {
    font-size: 1rem;
    line-height: 1em;
    font-weight: 500;
    letter-spacing: .001em;
    font-family: var(--fontPrimary);
    margin: 0;
    box-shadow: none;
    text-transform: none;
    text-decoration: none;
    overflow: hidden;
    white-space: nowrap;
    outline: 0; 
    border: 0;
    display: inline-flex;
    flex-shrink: 0;
    vertical-align: top; 
    align-items: center;
    justify-content: center;
    min-width: 100px;
    max-width: 100%;
    opacity: 1;
    transition: all .45s ease;
    cursor: pointer;

    @media (min-width: 992px) {
      border-radius: 2.5rem;
    }

    &:hover {
      color: var(--colorInverse);
    }
  }

  &.-link {
    color: #000000;
    border-radius: 1.5625rem;

    @media (min-width: 992px) {
      border-radius: 2.5rem;
    }

    &:hover {
      color: var(--colorInverse);
    }

    &.c-inverse {
      color: var(--colorInverse) !important;

      &:hover {
        background-color: var(--colorPrimary) !important;
      }
    }
  }

  &.-register {
    color: var(--colorInverse);
    background: var(--colorDark);
    border-radius: .5rem;

    @media (min-width: 992px) {
      border-radius: .4rem;
    }

    &:hover {
      color: var(--colorDark);
      background: var(--colorPrimary);
    }
  }

  &.-larger {
    height: 3.125rem;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;

    @media (min-width: 992px) {
      height: 4rem;
    }
  }

  &.-median {
    height: 2.5rem;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 180px;

    @media (min-width: 992px) {
      height: 3.125rem;
      min-width: 260px;
    }
  } 

  &.-smaller {
    height: 1.6rem;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 80px;
    font-size: 13px;

    @media (min-width: 992px) {
      height: 2rem;
      min-width: 100px;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 15px;
    }
  } 

  &.-icon {

    i {
      display: block;
      width: 16px;
      height: auto;
      margin-right: 16px;

      @media (min-width: 668px) {
        width: 20px;
        margin-right: 20px;
      }
    }
  }

  &.-wrap {
    overflow: hidden;
    position: relative;
    display: inline-flex;
    border-radius: 1.5625rem;
    max-width: 466px;
    width: 100%;

    &.-motion {
      opacity: 0;
    }

    @media (min-width: 992px) {
      border-radius: 2.5rem;
    }
  }

  &.-circle{
    background-color: var(--colorSupport);
    border-radius: 1100px;
    position: absolute;
    left:0;
    top:0;
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    pointer-events: none;

    &.-explode {
      animation: explode 0.5s forwards;
    }
    
    &.-desplode{
      animation: desplode 0.5s forwards;
    }
  }

  &.-text{
    position:relative;
    z-index: 4;
  }
}

@keyframes explode {
  
  0% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: rgba(0,0,0,.2);
  }
  100% {
    width: 1100px;
    height: 1100px;
    margin-left: -550px;
    margin-top: -550px;
    background-color: var(--colorSupport);
  }
}

@keyframes desplode {

  0% {
    width: 1100px;
    height: 1100px;
    margin-left: -550px;
    margin-top: -550px;
    background-color: var(--colorSupport);
  }
  100% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: rgba(0,0,0,.6);
  }
}