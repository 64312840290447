/**
 * components/_visualnetwork.scss
 */

.vnetwork {

  &.-wrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 100%;

    @media (max-width: 667px) {
      left: -80px;  
      padding-top: calc(100% + 160px);
      width: calc(100% + 160px);
    }
  }

  &.-orbit {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid rgba(255,255,255,.5);
  }

  &.-content {
    position: absolute;
    top: 50%;
    left: 0;
    height: 100%;
    padding: 17%;
    transform: translateY(-50%);
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(2, 1fr);
    column-gap: 16px;
    row-gap: 24px;

    @media (min-width: 992px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-template-rows: repeat(3, 1fr);
    }
  }

  &.-item {
    text-align: center;
  }

  &.-image {
    display: block;
    position: relative;

    img {
      max-width: 100%;
      border-radius: 100%;
    }
  }

  &.-name {
    margin-top: 1.15rem;
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: -.0125em;

    @media (min-width: 992px) {
      font-size: .9rem;
      line-height: 1.175rem;
    }

    @media (min-width: 1401px) {
      white-space: nowrap;
    }

    span {
      left: 50%;
      transform: translateX(-50%);
      position: relative;
      display: flex;
      width: fit-content; 
    }
  }

  &.-title {
    background-color: var(--colorInverse);
    color: var(--colorQuaternary);
    padding: 0 8px;
    min-width: 84px;
    height: 20px;
    font-size: 11px;
    font-weight: 400;
    line-height: 1;
    border-radius: 20px;
    letter-spacing: .02em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    margin-top: .6125rem;
    max-width: 100%;

    @media (max-width: 667px) {
      font-size: 10px;
    }

    .light & {
      background-color: rgba(255,255,255,.3);
      color: var(--colorInverse);
    }

    span {
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.-flag {
    position: absolute;
    bottom: 2px;
    right: 2px;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: var(--colorInverse);
    background-repeat: no-repeat;
    display: block;
    text-indent: -9999px;

    &.favor {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 13.333 11.667' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m-10.391 7.13a4.986 4.986 0 0 1 -1.667-3.509 3.01 3.01 0 0 1 3.2-3.18c1.8 0 2.413.834 3.465 2.04 1.054-1.206 1.668-2.04 3.466-2.04a3.01 3.01 0 0 1 3.2 3.18 4.985 4.985 0 0 1 -1.664 3.509l-5 4.978z' fill='%236250A2' transform='translate(12.058 -.441)'/%3E%3C/svg%3E");
      background-size: 14px auto;
      background-position: center 55%;
    }

    &.payment {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 9.115 15.428' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11.02-39.211c0-2.176-1.906-2.7-3.684-3.144v-3.7a5.724 5.724 0 0 1 2.7.889l.524-1.271a6.373 6.373 0 0 0 -3.224-.969v-1.794h-1.017v1.81c-2.462.206-3.589 1.572-3.589 3.144 0 2.16 1.842 2.716 3.589 3.144v3.684a5.539 5.539 0 0 1 -3.272-1.271l-.587 1.241a6.424 6.424 0 0 0 3.859 1.382v1.795h1.017v-1.795c2.509-.207 3.684-1.572 3.684-3.145zm-6.734-5.1c0-.842.619-1.556 2.033-1.731v3.43c-1.143-.299-2.033-.712-2.033-1.697zm3.049 6.877v-3.414c1.175.318 2.128.715 2.128 1.731.001.859-.65 1.542-2.127 1.685z' fill='%236250A2' stroke='%236250A2' stroke-width='.5' transform='translate(-2.155 49.45)'/%3E%3C/svg%3E");
      background-size: 8px auto;
      background-position: center;
    }

    &.favor-white {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 13.333 11.667' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m-10.391 7.13a4.986 4.986 0 0 1 -1.667-3.509 3.01 3.01 0 0 1 3.2-3.18c1.8 0 2.413.834 3.465 2.04 1.054-1.206 1.668-2.04 3.466-2.04a3.01 3.01 0 0 1 3.2 3.18 4.985 4.985 0 0 1 -1.664 3.509l-5 4.978z' fill='%23FFFFFF' transform='translate(12.058 -.441)'/%3E%3C/svg%3E");
      background-size: 14px auto;
      background-position: center 55%;
    }

    &.payment-white {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 9.115 15.428' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11.02-39.211c0-2.176-1.906-2.7-3.684-3.144v-3.7a5.724 5.724 0 0 1 2.7.889l.524-1.271a6.373 6.373 0 0 0 -3.224-.969v-1.794h-1.017v1.81c-2.462.206-3.589 1.572-3.589 3.144 0 2.16 1.842 2.716 3.589 3.144v3.684a5.539 5.539 0 0 1 -3.272-1.271l-.587 1.241a6.424 6.424 0 0 0 3.859 1.382v1.795h1.017v-1.795c2.509-.207 3.684-1.572 3.684-3.145zm-6.734-5.1c0-.842.619-1.556 2.033-1.731v3.43c-1.143-.299-2.033-.712-2.033-1.697zm3.049 6.877v-3.414c1.175.318 2.128.715 2.128 1.731.001.859-.65 1.542-2.127 1.685z' fill='%23FFFFFF' stroke='%23FFFFFF' stroke-width='.5' transform='translate(-2.155 49.45)'/%3E%3C/svg%3E");
      background-size: 8px auto;
      background-position: center;
    }
  }

  &.-abstract {
    font-size: 11px;
    line-height: 18px;
    color: #3E3E3E;
    margin-top: 1.75rem;
    text-align: center;

    @media (max-width: 667px) {
      margin-left: -7px;
      margin-right: -7px;
    }
  }
}