/**
 * settings/_main.scss
 */

/* ==========================================================================
   Imports
   ========================================================================== */

// colors
$color-base: #525354; 
$color-inverse: #FFFFFF;
$color-dark: #444444;
$color-light: #F5F5F5;

$color-primary: #FFA900;
$color-secondary: #A51E5B;
$color-tertiary: #00A1D9;
$color-quaternary: #6250A2;

$color-support: #A2CC3A;

// fonts
$font-primary: 'Montserrat', Verdana, Arial, sans-serif;

// container limits
$grid-breakpoints:(
  xs: 0,
  sm: 668px,
  md: 992px,
  lg: 1440px
);

// container cols
$grid-columns: 12;

* {
  text-rendering: optimizeLegibility;
}
  
body {
  --colorBase: #{$color-base};
  --colorInverse: #{$color-inverse};
  --colorDark: #{$color-dark};
  --colorLight: #{$color-light};

  --colorPrimary: #{$color-primary};
  --colorSecondary: #{$color-secondary};
  --colorTertiary: #{$color-tertiary};
  --colorQuaternary: #{$color-quaternary};
  
  --colorSupport: #{$color-support};

  --fontPrimary: #{$font-primary};

  background: #fff;
  color: var(--colorBase);
  font: 400 1rem/1.7 var(--fontPrimary);
}

img {
  max-width: 100%;
}

a {
  color: inherit;
}

// spacers
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
  0: 0,
  1: ($spacer * 1),
  2: ($spacer * 1.5),
  3: ($spacer * 2),
  4: ($spacer * 2.5),
  5: ($spacer * 3),
  6: ($spacer * 3.5),
  7: ($spacer * 4),
  8: ($spacer * 4.5),
  9: ($spacer * 5),
  10: ($spacer * 5.5)
), $spacers);