/**
 * components/_aside.scss
 */

.aside {

  &.-wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 65%;
    bottom: 0;

    &:before {
      content: "";
      background-color: rgba(0,0,0,.4);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
    }
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }

  &.-info {
    left: 36px;
    bottom: 4.625rem;
    position: absolute;

    @media (min-width: 1780px) {
      left: 60px;
    }
  }

  &.-title {
    background-color: rgba(255, 255, 255, 0.3);
    color: var(--colorInverse);
    padding: 0 1rem;
    min-width: 115px;
    height: 28px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    border-radius: 28px;
    letter-spacing: .02em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    margin-top: 1.25rem;
    max-width: 100%;

    @media (min-width: 1600px) {
      font-size: .8rem;
      margin-top: 1rem;
    }
  }

  &.-name {
    color: var(--colorInverse);
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: .02em;

    @media (min-width: 1600px) {
      font-size: 1.1rem;
    }
  }
}