/**
 * components/_menu.scss
 */

.menu {

  &.-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 4rem;
    z-index: 14;

    &.-primary {
      background: var(--colorPrimary);
    }

    &.-secondary {
      background: var(--colorSecondary);
    }

    &.-tertiary {
      background: var(--colorTertiary);
    }

    &.-quaternary {
      background: var(--colorQuaternary);
    }

    @media (min-width: 992px) {
      height: 6.25rem;
    }

    @media (min-width: 1600px) {
      height: 5rem;
    }
  }

  .container, &.-cnt {
    height: 100%;
  }

  &.-cnt {
    max-width: 1640px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }

  &.-logo {
    max-width: 100%;
    width: 146px;
    flex: 0 0 146px;
  
    @media (min-width: 992px) {
      width: 257px;
      flex: 0 0 257px;
    }
  }

  &.-links {
    display: flex;
    flex-flow: row;
    justify-content: space-around;

    @media (max-width: 991px) {
      position: absolute;
      width: calc(100% - 60px);
      right: 0;
      top: 0;
      height: 120vh;
      padding: 90px 20px;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-end;
      background: var(--colorTertiary);
      transform: translate3d(100%,0,0);
      transition: all .3s ease;
      z-index: 14;

      &.-isOpen {
        transform: translate3d(0,0,0);
      }
    }
  }

  &.-link {
    display: block;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    word-break: keep-all;
    background: none;
    color: var(--colorInverse);
    text-decoration: none;
    position: relative;
    transition: all .3s ease;

    &.-active {

      &:after {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        width: calc(100% - 2rem);
        background-color: var(--colorInverse);
        content: "";
      }
    }

    &:hover {
      
      @media (min-width: 992px) {
        background: rgba(255,255,255,.1);
      }
    }
  }

  &.-profile {

    @media (max-width: 991px) {
      position: absolute;
      width: calc(100% - 60px);
      right: 0;
      top: 0;
      display: flex;
      justify-content: flex-end;
      flex-wrap: nowrap;
      padding: 10px 20px;
      transform: translate3d(100%,0,0);
      transition: all .3s ease;
      z-index: 15;

      &.-isOpen {
        transform: translate3d(0,0,0);
      }
    }

    &-link {
      padding: 6px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {

        picture {
          border: #ffffff 1px solid;
        }
      }
    }

    &-logged {

      picture {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        overflow: hidden;
        display: block;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%;
        }
      }
    }

    &.-dropdown {
      background-color: var(--colorLight);
      color: var(--colorBase);
      border-radius: 20px;
      font-size: .8rem;
      font-weight: 400;
      padding: 5px 10px;
      transition: none;

      width: 150px;
      position: absolute;
      right: 15px;
      display: flex;
      flex-flow: column;
      transform: translateY(-170px);

      a {
        text-decoration: none;
        text-align: center;
        margin: 5px 0;
      }

      @media (max-width: 991px) {
        right: 30px;
        top: 70px;
        border-radius: 10px;

        a {
          padding: 5px 0;
        }
      }

      &.-open {
        transform: translateY(80px);
        height: auto;
        @media (max-width: 991px) {
          transform: translateY(0);
        }
      }

      a:hover {
        font-weight: 600;
      }
    }

    &-login {

      @media (max-width: 991px) {

        .icon {
          width: 26px;
          height: 26px;
          margin-right: 10px;
          display: block;
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469-68.382812 0-132.667969 26.628906-181.019531 74.980469-48.351563 48.351562-74.980469 112.636719-74.980469 181.019531 0 68.378906 26.628906 132.667969 74.980469 181.019531 48.351562 48.351563 112.636719 74.980469 181.019531 74.980469 68.378906 0 132.667969-26.628906 181.019531-74.980469 48.351563-48.351562 74.980469-112.640625 74.980469-181.019531 0-68.382812-26.628906-132.667969-74.980469-181.019531zm-308.679687 367.40625c10.707031-61.648438 64.128906-107.121094 127.660156-107.121094 63.535156 0 116.953125 45.472656 127.660156 107.121094-36.347656 24.972656-80.324218 39.613281-127.660156 39.613281s-91.3125-14.640625-127.660156-39.613281zm46.261718-218.519531c0-44.886719 36.515626-81.398438 81.398438-81.398438s81.398438 36.515625 81.398438 81.398438c0 44.882812-36.515626 81.398437-81.398438 81.398437s-81.398438-36.515625-81.398438-81.398437zm235.042969 197.710937c-8.074219-28.699219-24.109375-54.738281-46.585937-75.078125-13.789063-12.480469-29.484375-22.328125-46.359375-29.269531 30.5-19.894531 50.703125-54.3125 50.703125-93.363281 0-61.425782-49.976563-111.398438-111.402344-111.398438s-111.398438 49.972656-111.398438 111.398438c0 39.050781 20.203126 73.46875 50.699219 93.363281-16.871093 6.941406-32.570312 16.785156-46.359375 29.265625-22.472656 20.339844-38.511718 46.378906-46.585937 75.078125-44.472657-41.300781-72.355469-100.238281-72.355469-165.574219 0-124.617188 101.382812-226 226-226s226 101.382812 226 226c0 65.339844-27.882812 124.277344-72.355469 165.578125zm0 0' fill='%23ffffff'/%3E%3C/svg%3E");
        }
      }

      a {
        text-decoration: none;
        color: var(--colorInverse);
        font-size: .8rem;
        display: flex;
        align-items: center;
        line-height: 1.4;
        letter-spacing: .03em;
        padding: 6px 1rem;
        word-break: keep-all;
        background: none;
        transition: all .3s ease;
      
        &:hover {

          @media (min-width: 992px) {
            background: rgba(255,255,255,.1);
          }
        }
      }
    }
  }

  &.-open {
    position: absolute;
    top: 0;
    right: 20px;
    width: 60px;
    height: 4rem;
    transition: all .3s ease;
    
    @media (min-width: 992px) {
      display: none;
    }

    &.-isOpen {
      left: 0;
      width: 100%;
      height: 120vh;
      background-color: rgba(0,0,0,.4);
    }
    
    &-link {
      display: block;
      padding: 0;
      background: none;
      box-shadow: none;
      border: 0;
      outline: 0;
      width: 60px;
      height: 4rem;
      text-decoration: none;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &-bar {
      position: relative;
      width: 30px;
      height: 30px;
      display: block;

      &:before, &:after {
        position: absolute;
        height: 2px;
        width: 30px;
        left: 0;
        background: var(--colorInverse);
        transform: none;
        content: "";
        transition: all .6s ease;

        .-isOpen & {
          transition-delay: .2s;
        }
      }

      &:before {
        top: 10px;

        .-isOpen & {
          transform: rotate(45deg) translate3D(3px,3px,0);
        }
      }

      &:after {
        bottom: 10px;

        .-isOpen & {
          transform: rotate(-45deg) translate3D(3px,-3px,0);
        }
      }
    }

  }
}