/**
 * components/_detail.scss
 */

.detail {

  &.-wrap {
    padding: 2rem 10px 3.8rem;

    @media (min-width: 992px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .container {
      background: var(--colorInverse);
      padding-top: 1rem;
      padding-bottom: 2rem;

      @media (min-width: 992px) {
        display: flex;
        flex-flow: row;
      }
    }
  }

  &.-list {

    @media (min-width: 992px) {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 50px;

      &.-order-1 {
        order: 1
      }

      &.-order-2 {
        order: 2
      }
    }
  }

  &.-box {
    padding: 1rem 0;
    color: #888888;

    h2, h3, h4 {
      font-weight: 500;
      font-size: 1.15rem;
      margin: 0 0 10px;
    }
  }

  &.-item {

    &-400 {
      max-width: 400px;
    }

    p, span {
      font-weight: 500;
      font-size: 1rem;
      color: var(--colorDark);
    }
  }

  &.-video {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;
    border-radius: 20px;

    iframe, video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
    }
  }

  &.-content {
    padding: 1rem 1rem calc(1rem - 10px);
    background: var(--colorLight);
    font-size: 0;

    span {
      display: inline-block;
      font-size: .8rem;
      line-height: 1.15;
      font-weight: 400;
      color: var(--colorInverse);
      background-color: #363636;
      padding: 8px 20px;
      border-radius: 20px;
      margin: 0 10px 10px 0;

      @media (min-width: 992px) {
        font-size: .9rem;
      }

      &.b-3 {
        background-color: var(--colorTertiary);
      }

      &.b-4 {
        background-color: var(--colorQuaternary);
      }
    }

    p {
      margin: 0 0 10px;
    }
  }
}