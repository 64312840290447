/**
 * objects/_aux.scss
 */

 #root {
   min-height: 100vh;
   background-color: var(--colorLight);
   overflow: hidden;
   position: relative;
 }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}

.center {
  text-align: center;
}

.left {

  &-sm {

    @media (max-width: 991px) {
      text-align: left;
    }
  }

  &-lg {

    @media (min-width: 992px) {
      text-align: left;
    }
  }
}

.hidden {

  &-sm {

    @media (max-width: 991px) {
      display: none;
    }
  }

  &-lg {

    @media (min-width: 992px) {
      display: none;
    }
  }
}

.upper {
  text-transform: uppercase;
}