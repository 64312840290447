/**
 * components/_facaparte.scss
 */

 .facaparte {

  &.-main {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;

    margin: 40px 0;
  }

  &.-video-container {
    margin-top: 60px;
    width: 735px;

    video {
      position: relative;
      z-index: 2;
    }

    .smile {
      width: 106px;
      
      animation-duration: 0s;
      animation-delay: 0s;

      &-2 {
        top: 140px;
        right: calc(100vw - 720px * 1.495);
      }

      &-4 {
        top: 595px;
        right: calc(100vw - 720px * 0.75);

        svg {
          transform: rotate(-135deg);
        }
      }
    }


    @media (max-width: 991px) {
      margin-top: 0;
      width: 100%;
    }
  }

  &.-text {
    max-width: 1088px;

    display: flex;
    flex-flow: column;
    align-items: center;
    color: #ffffff;
    text-align: center;

    margin: 72px 0;

    @media (max-width: 991px) {
      margin: 40px 0 30px;
    }

    h2 {
      font-weight: 400;
    }

    p {
      font-weight: lighter;
      margin-top: 40px;
      max-width: 812px;
    }
  }

  &.-cta {

    .button.-wrap {
      margin-top: 0 !important;
    }

    a.button {
      padding: 22px 64px;
    }
  }


  &.-bg {
    @media (max-width: 991px) {
      display: none;
    }
  }

  &.-bubbles {

    &.-col {
      height: calc(100% - 216px + 116px);
      margin-top: -58px;

      position: absolute;
      display: flex;
      flex-flow: column;
      justify-content: space-between;



      &.-left {
        left: 0;
        margin-left: -36px;
      }
      &.-right {
        right: 0;
        margin-right: -36px;
      }

      .-elmt {
        display: block;
        width: 122px;
        height: 122px;

        border-radius: 50%;
      }
    }
  }
 }