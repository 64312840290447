/**
 * components/_nav.scss
 */

.nav {

  &.-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 4rem;
    align-items: center;
    display: flex;
    z-index: 9;

    &.-light {
      background: -webkit-linear-gradient(top, rgba($color-light,1) 50%,rgba($color-light,0) 100%);
      background: linear-gradient(to bottom, rgba($color-light,1) 50%,rgba($color-light,0) 100%);

      &-sm {

        @media (max-width: 991px) {
          background: -webkit-linear-gradient(top, rgba($color-light,1) 50%,rgba($color-light,0) 100%);
          background: linear-gradient(to bottom, rgba($color-light,1) 50%,rgba($color-light,0) 100%);
        }
      }
    }

    &.-quaternary {
      background: var(--colorQuaternary);

      &-sm {

        @media (max-width: 991px) {
          background: var(--colorQuaternary);
        }
      }
    }

    @media (min-width: 992px) {
      height: 6.25rem;
    }

    @media (min-width: 1600px) {
      height: 5rem;
    }
  }

  &.-cnt {
    max-width: 1640px;
    margin-left: auto;
    margin-right: auto;
  }

  &.-logo {
    width: 100%;
    max-width: 200px;
  
    @media (min-width: 992px) {
      max-width: 257px;
    }
  }

  &.-back {

    &.-wrap {
      position: relative;
      width: 2.5rem;
      height: 2.5rem;

      @media (min-width: 992px) {
        width: 3.125rem;
        height: 3.125rem;
      }

      @media (min-width: 1600px) {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    &.-link {
      border-radius: 50%;
      background-color: var(--colorInverse);
      text-decoration: none;
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        display: block;
        width: 9px;
        height: auto;
      }
    }
  }
}