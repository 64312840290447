/**
 * components/_share.scss
 */

.share,.incomplete-profile {

  &.-wrap {
    padding: 15px;
    background: var(--colorInverse);
    border-radius: 5px;

    @media (min-width: 668px) {
      border-radius: 7px;
    }

    @media (min-width: 992px) {
      padding: 30px 20px;
      border-radius: 10px;
    }
  }

  &.-wrap-incomplete {
    padding: 15px;
    background: var(--colorInverse);
    border-radius: 5px;
    margin: 0 -1rem;

    @media (min-width: 668px) {
      border-radius: 7px;
    }

    @media (min-width: 992px) {
      padding: 30px 20px;
      border-radius: 10px;
      max-width: 500px;
      margin: 0;
    }
  }

  &.-title {
    font-size: .95rem;
    text-align: center;

    @media (min-width: 668px) {
      font-size: 1.125rem;
    }

    @media (min-width: 1600px) {
      font-size: .9rem;
    }
  }

  .button.-link {
    border-radius: 8px;

    @media (max-width: 667px) {
      font-size: .9rem;
    }
  }

  &.-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
  }

  &.-incomplete-group {
    display: flex;
    flex-flow: column;
    margin: 1rem 0;
    font-size: 0.9rem;

    @media (min-width: 668px) {
    font-size: .8rem;
    }

    .-steps-finished {
      padding-top: 2rem;
    }
  }

  &.-link {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    height: 2.5rem;
    width: 2.5rem;
    flex: 0 0 2.5rem;
    
    padding: 0;
    margin-left: .5rem;
    margin-right: .5rem;

    text-decoration: none;
    max-width: 100%;

    transition: all .3s ease;

    @media (min-width: 992px) {
      height: 3.125rem;
      width: 3.125rem;
      flex: 1;
      margin-left: .625rem;
      margin-right: .625rem;
    }

    button, [class^=react-share] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      outline: none;

      &:hover, &:active, &:visited {
        outline: none;
      }
    }

    &.-twitter:hover {

      i {

        path {
          fill: #1da1f2;
        }
      }
    }

    &.-facebook:hover {

      i {

        path {
          fill: #4267b2;
        }
      }
    }

    &.-whatsapp:hover {

      i {

        path {
          fill: #00cc68;
        }
      }
    }

    &.-linkedin:hover {

      i {

        path {
          fill: #0072B0;
        }
      }
    }

    i {
      display: block;
      width: 23px;
      height: auto;

      @media (min-width: 668px) {
        width: 27px;
      }

      path {
        fill: #525354;
        transition: all .3s;
      }
    }
  } 
}