/**
 * objects/_mosaic.scss
 */

.mosaic {

  &.-wrap {
    position: relative;
    max-width: 535px;
    margin: 0 auto;

    @media (max-height: 640px) {
      max-width: 78%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.-content {
    width: 100%;
    height: 0;
    padding-top: 110.84%;
    max-width: 760px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    transform: scale(.8);

    @media (min-width: 1640px) {
      padding-top: 108%;
    }
  }

  &.-pic {
    position: absolute;
    transform: translate(0);
    transition: transform .8s cubic-bezier(.87,.04,.76,.22), opacity .7s;
    transition-delay: 1s;

    &-1 {
      max-width: 77%;
      top: 14%;
      left: 0;

      &.-motion {
        transform: translate(25%,25%);
      }
    }

    &-2 {
      max-width: 48%;
      top: 0;
      right: 0;

      &.-motion {
        transform: translate(-25%,25%);
      }
    }

    &-3 {
      max-width: 68%;
      left: 16%;
      bottom: 0;

      &.-motion {
        transform: translate(0,-35%);
      }
    }
  }
}