/**
 * components/_filter.scss
 */

.filter {

  &.-wrap {
    background-color: var(--colorInverse);
    padding-top: 2.6rem;
    padding-bottom: 2.6rem;
  }

  &.-config {
    display: flex;
    flex-flow: column;
    margin-left: -15px;
    margin-right: -15px;

    @media (min-width: 992px) {
      flex-flow: row;
      flex-wrap: nowrap;
    }
  }

  &.-col {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    padding-left: 15px;
    padding-right: 15px;
    flex: 0 0 100%;
    max-width: 100%;

    @media (min-width: 992px) {

      &-1 {
        flex: 0 0 28%;
        max-width: 28%;
      }
  
      &-2 {
        flex: 0 0 36%;
        max-width: 36%;
      }
    }

    @media (max-width: 991px) {

      &-1 {
        margin-top: .8rem;
      }
    }
  }

  &.-search {

    &, form {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      width: 100%;

      .spinner-container {
        width: 100%;
        margin-top: .2rem;

        @media (min-width: 992px) {
          width: 60px;
        }

        &.-off {
          display: none;
        }
      }
    }
    

    &-group {
      position: relative;
      margin-top: .2rem;
      width: 100%;

      @media (min-width: 992px) {
        width: calc(100% - 60px);
      }

      input[type="search"], input[type="text"] {
        width: 100%;
        display: block;
        padding-left: calc(.3rem + 48px);
        padding-right: .4rem;
        height: 46px;
        background: var(--colorLight);
        border: 0;
        border-radius: 10px;
        text-align: left;
        color: #000000;
        font-size: .9rem;
        letter-spacing: .03em;
        outline: none;
        position: relative;

        @media (min-width: 992px) {
          height: 60px;
          padding-left: calc(1rem + 60px);
          padding-right: 1rem;
        }
      }

      input[type="submit"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 48px;
        height: 48px;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m20.837 965.362a7.837 7.837 0 1 0 4.933 13.918l5.844 5.844a.812.812 0 0 0 1.149-1.148l-5.844-5.845a7.828 7.828 0 0 0 -6.081-12.769zm0 1.622a6.216 6.216 0 1 1 -6.216 6.216 6.2 6.2 0 0 1 6.216-6.216z' transform='translate(-13 -965.362)'/%3E%3C/svg%3E");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 14px auto;
        border: 0;
        outline: 0;
        padding: 0;
        box-shadow: none;
        display: block;
        text-indent: -9999px;
        cursor: pointer;
        transition: all .35s ease;

        &:hover {
          background-color: rgba(98,80,162,.1);
        }

        @media (min-width: 992px) {
          width: 60px;
          height: 60px;
          background-size: 20px auto;
        }
      }
    }
  }

  &.-actions {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    margin-right: -4px;
    margin-left: -4px;

    .-col-1  &{

      @media (min-width: 992px) {
        margin-right: -8px;
        margin-left: -8px;
      }
    }
  }

  &.-action {
    padding-left: 4px;
    padding-right: 4px;

    @media (max-width: 991px) {
      flex: 1;
    } 

    .-col-1  &{

      @media (min-width: 992px) {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    &-button {
      font-size: .8rem;
      letter-spacing: .03em;
      line-height: 1em;
      font-weight: 500;
      font-family: var(--fontPrimary);
      margin: 5px 0;
      padding: 0 .8rem;
      box-shadow: none;
      text-transform: none;
      text-decoration: none;
      overflow: hidden;
      word-break: keep-all;
      outline: 0; 
      border: 1px solid var(--colorBase);
      background: var(--colorInverse);
      border-radius: 30px;
      display: inline-flex;
      flex-shrink: 0;
      vertical-align: top; 
      align-items: center;
      justify-content: center;
      min-width: 100px;
      max-width: 100%;
      height: 40px;
      opacity: 1;
      transition: all .45s ease;
      cursor: pointer;
      transition: all .35s ease;

      &:hover {
        background-color: var(--colorLight);
        border-color: var(--colorQuaternary);
      }

      @media (max-width: 991px) {
        width: 100%;
      }

      @media (min-width: 992px) {
        height: 50px;
        padding: 0 1rem;
        font-size: .9rem;
      }

      &.-icon {

        i {
          display: block;
          width: 16px;
          height: auto;
          margin-left: 16px;
    
          @media (min-width: 668px) {
            width: 20px;
            margin-left: 20px;
          }
        }
      }
    }
  }
}