/**
 * objects/_grid.scss
 */

.grid {

  &.-wrap {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;

    @media (min-width: 992px) {
      grid-template-columns: repeat(12, 1fr);
      max-width: 1640px;
      margin-left: auto;
      margin-right: auto;
    }

    &.-center {
      align-items: center;
    }
  }

  &.-row {
    
    &-6-1-5 {
      grid-template-areas:
        'a a a a'
        'b b b b';

      @media (min-width: 992px) {
        grid-template-areas: 
          'a a a a a a b b b b b b';
      }

      @media (min-width: 1240px) {
        grid-template-areas: 
          'a a a a a a . b b b b b';
      }
    }

    &-6-1-4 {
      grid-template-areas:
        'a a a a'
        'b b b b';

      @media (min-width: 992px) {
        grid-template-areas: 
          'a a a a a a b b b b b .';
      }

      @media (min-width: 1240px) {
        grid-template-areas: 
          'a a a a a a . b b b b .';
      }
    }

    &-3-6-3 {
      grid-template-areas:
        'a a a a';

      @media (min-width: 992px) {
        grid-template-areas: 
          '. . . a a a a a a . . .';
      }
    }

    &-2-8-2 {
      grid-template-areas:
        'a a a a';

      @media (min-width: 992px) {
        grid-template-areas: 
          '. . a a a a a a a a . .';
      }
    }

    &-1-10-1 {
      grid-template-areas:
        'a a a a';

      @media (min-width: 992px) {
        grid-template-areas: 
          '. a a a a a a a a a a .';
      }
    }

    &-1-5-1-4-1 {
      grid-template-areas:
        'b b b b'
        'a a a a';
      grid-gap: 0;

      @media (min-width: 992px) {
        grid-template-areas: 
          '. a a a a a . b b b b .';
        grid-gap: 16px;
      }
    }

    &-1-4-1-5-1 {
      grid-template-areas:
        'a a a a'
        'b b b b';

      @media (min-width: 992px) {
        grid-template-areas: 
          '. a a a a . b b b b b .';
      }
    }
  }

  &.-col {

    &-a {
      grid-area: a;
    }

    &-b {
      grid-area: b;
    }

    &-c {
      grid-area: c;
    }

    &-d {
      grid-area: d;
    }
  }

  &.-self-end {
    align-self: flex-end;
  }
}

.wrap {
  margin-left: auto;
  margin-right: auto;
}

.container {
  position: relative;
  padding-left: 36px;
  padding-right: 36px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: calc(1640px + 120px);

  @media (min-width: 1780px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .col {
    position: relative;
    width: 100%;
    max-width: 100%;
    min-height: 1px;
    padding-right: 8px;
    padding-left: 8px;

    @for $i from 1 through 12 {
    
      &.-c#{$i} {
        @include make-col($i);
      }

      &.-offset#{$i} {
        @include make-col-offset($i);
      }
    }
  
    @media (min-width: 992px) { 
  
      @for $i from 1 through 12 {
      
        &.-c#{$i}-lg {
          @include make-col($i);
        }

        &.-offset#{$i}-lg {
          @include make-col-offset($i);
        }
      }
  
      &.-c-lg {
        width: auto;
        max-width: none;
      }
    }
  }
}

.flex {
  display: flex;

  &.-center {
    align-items: center;
    justify-content: center;
  }

  &.-column {
    flex-flow: column;
  }

  &.-row {
    flex-flow: row;
  }

  &.-wrap {
    flex-wrap: wrap;
  }

  &.-nowrap {
    flex-wrap: nowrap;
  }

  &.-align-center {
    align-items: center;
  }

  &.-justify-space-between {
    justify-content: space-between;
  }

  &.-justify-end {
    justify-content: flex-end;
  }

  &.-justify-center {
    justify-content: center;
  }
}