/**
 * components/_page.scss
 */

.page {

  &.-container {
    padding-top: 4rem;

    @media (min-width: 992px) {
      padding-top: 6.25rem;
    }

    @media (min-width: 1600px) {
      padding-top: 5rem;
    }
  }
}