/**
 * objects/_color.scss
 */

// cores

.c {

  &-1 {
    color: var(--colorPrimary);
  }

  &-2 {
    color: var(--colorSecondary);
  }

  &-3 {
    color: var(--colorTertiary);
  }

  &-4 {
    color: var(--colorQuaternary);
  }

  &-support {
    color: var(--colorSupport);
  }

  &-base {
    color: var(--colorBase);
  }

  &-inverse {
    color: var(--colorInverse);
  }

  &-dark {
    color: var(--colorDark);
  }

  &-light {
    color: var(--colorLight);
  }
}

// fundos

.b {

  &-1 {
    background-color: var(--colorPrimary);
  }

  &-2 {
    background-color: var(--colorSecondary);
  }

  &-3 {
    background-color: var(--colorTertiary);
  }

  &-4 {
    background-color: var(--colorQuaternary);
  }

  &-support {
    background-color: var(--colorSupport);
  }

  &-base {
    background-color: var(--colorBase);
  }

  &-inverse {
    background-color: var(--colorInverse);
  }

  &-dark {
    background-color: var(--colorDark);
  }

  &-light {
    background-color: var(--colorLight);
  }
}