/**
 * components/_social.scss
 */

 .social {

  &.-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 991px){
      justify-content: flex-start;
      align-items: center;
      flex-flow: column;
    }
  }

  &.-title {
    margin-bottom: 3rem;
    white-space: nowrap;
  }

  &.-link {
    display: flex;
    position: relative;
    background: var(--colorDark);
    border-radius: calc(4.365rem / 2);
    height: 3.125rem;
    flex-flow: row;
    padding: 10px 35px 10px 20px;
    margin: .625rem 0;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    max-width: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    transition: all .3s ease;

    @media (max-width: 991px){
      max-width: 280px;
    }

    @media (min-width: 992px) {
      height: 3.5rem;
      flex: 1;
      margin: 1.2rem .5rem;
    }

    button, [class^=react-share] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      outline: none;

      &:hover, &:active, &:visited {
        outline: none;
      }
    }

    &.-twitter:hover {
      background: #1da1f2;
    }

    &.-facebook:hover {
      background: #4267b2;
    }

    &.-whatsapp:hover {
      background: #00cc68;
    }

    &.-linkedin:hover {
      background: #0072B0;
    }

    h2, h3, h4 {
      font-size: .8125rem;
      font-weight: 500;
      letter-spacing: .02em;
      padding-top: 2px;
      white-space: nowrap;
    }

    i {
      display: block;
      width: 27px;
      height: auto;

      path {
        fill: #fff;
      }
    }
  } 
}