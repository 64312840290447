/**
 * components/_notfound.scss
 */

.notfound {

  &.-wrap {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }

  &.-network {
    
    &.-content {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
    }

    &.-list {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 0 15px;
    }

    &.-left {

      .-item {
  
        &:nth-of-type(odd) {
          margin-left: calc((100vh / 6) - 4vh);
        }
      }
    }

    &.-right {
      align-items: flex-end;

      .-item {
  
        &:nth-of-type(odd) {
          margin-right: calc((100vh / 6) - 4vh);
        }
      }
    }

    &.-item {
      width: calc((100vh / 6) - 4vh);
      height: auto;

      img {
        border-radius: 100%;
      }
    }
  }

  .button.-larger {
    max-width: 536px;
  }
}