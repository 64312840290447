/**
 * objects/_section.scss
 */

.section {

  &.-cover {

    @media (max-width: 991px) {
      overflow: hidden;
    }
  }

  &.-entire {
    min-height: 100vh;
  }

  &.-holder {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;

    @media (min-width: 992px) {
      padding-top: 5.4rem;
      padding-bottom: 5.4rem;
    }
  }
}