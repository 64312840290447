/**
 * components/_logo.scss
 */

.logo {
  width: 100%;
  max-width: 240px;

  @media (min-width: 992px) {
    max-width: 300px;
  }
}