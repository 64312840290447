/**
 * components/_explore.scss
 */

.explore {

  &.-page {
    padding-top: 30px;
  }

  &.-wrap {
    padding-left: 30px;
    padding-right: 30px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }

  &.-listing {
    display: flex;
    margin-left: -30px;
    margin-right: -30px;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 30px;

    min-height: 308px;

    @media (min-width: 992px) {
      min-height: 236px;
    }
  }

  &.-noresults {
    font-size: 1rem;
    height: 2rem;
    align-self: center;
    width: 100%;
    text-align: center;
  }

  &.-list-item {
    padding: 20px;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;

    @media (min-width: 992px) {
      flex: 0 0 33.3333333%;
      max-width: 33.3333333%;
      padding: 40px;

      &:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:nth-of-type(3)) {
        border-top: 1px solid #C4C4C4;
      }
    }
  }

  &.-profile {
    display: flex;
    flex-flow: row;
    align-items: flex-start;
  }

  &.-image {
    position: relative;
    flex: 0 0 60px;
    width: 60px;
    height: 60px;
    margin-right: 10px;

    @media (min-width: 992px) {
      flex: 0 0 80px;
      width: 80px;
      height: 80px;
      margin-right: 20px;
    }

    .image-container {
      max-width: 100%;
      border-radius: 100%;
      width: 60px;
      height: 60px;
      background-size: cover;

      @media (min-width: 992px) {
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
    }

    &-link {
      display: block;
      width: 100%;
      height: 100%;
      text-decoration: none;
    }
  }

  &.-flag {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: var(--colorTertiary);
    background-repeat: no-repeat;
    display: block;
    text-indent: -9999px;

    @media (min-width: 992px) {
      width: 25px;
      height: 25px;
      bottom: 2px;
      right: 2px;
    }

    &.favor {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 13.333 11.667' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m-10.391 7.13a4.986 4.986 0 0 1 -1.667-3.509 3.01 3.01 0 0 1 3.2-3.18c1.8 0 2.413.834 3.465 2.04 1.054-1.206 1.668-2.04 3.466-2.04a3.01 3.01 0 0 1 3.2 3.18 4.985 4.985 0 0 1 -1.664 3.509l-5 4.978z' fill='%23FFFFFF' transform='translate(12.058 -.441)'/%3E%3C/svg%3E");
      background-size: 10px auto;
      background-position: center 55%;

      @media (min-width: 992px) {
        background-size: 14px auto;
      }
    }

    &.payment {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 9.115 15.428' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11.02-39.211c0-2.176-1.906-2.7-3.684-3.144v-3.7a5.724 5.724 0 0 1 2.7.889l.524-1.271a6.373 6.373 0 0 0 -3.224-.969v-1.794h-1.017v1.81c-2.462.206-3.589 1.572-3.589 3.144 0 2.16 1.842 2.716 3.589 3.144v3.684a5.539 5.539 0 0 1 -3.272-1.271l-.587 1.241a6.424 6.424 0 0 0 3.859 1.382v1.795h1.017v-1.795c2.509-.207 3.684-1.572 3.684-3.145zm-6.734-5.1c0-.842.619-1.556 2.033-1.731v3.43c-1.143-.299-2.033-.712-2.033-1.697zm3.049 6.877v-3.414c1.175.318 2.128.715 2.128 1.731.001.859-.65 1.542-2.127 1.685z' fill='%23FFFFFF' stroke='%23FFFFFF' stroke-width='.5' transform='translate(-2.155 49.45)'/%3E%3C/svg%3E");
      background-size: 7px auto;
      background-position: center;

      @media (min-width: 992px) {
        background-size: 8px auto;
      }
    }
  }

  &.-info {
    flex: 0 0 calc(100% - 80px);
    max-width: calc(100% - 80px);

    @media (min-width: 992px) {
      flex: 0 0 calc(100% - 100px);
      max-width: calc(100% - 100px);
    }
  }

  &.-name {
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0;
    font-weight: 600;
    color: var(--colorBase);
    word-break: keep-all;
    white-space: nowrap;
    margin-top: .4rem;

    @media (min-width: 1600px) {
      margin-top: .2rem;
    }

    a {
      text-decoration: none;
    }
  }

  &.-details {
    display: flex;
    flex-wrap: nowrap;
    flex-flow: row;
    align-items: flex-start;
    margin-top: .5rem;
    margin-bottom: 1rem;
    position: relative;
    width: 100%;
    overflow: hidden;

    @media (max-width: 991px) {
      width: calc(100% + 35px);
    }
    
    &:after { 
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 20%;
      content: "";
      background: -webkit-linear-gradient(left, rgba(245,245,245,0) 0%,rgba(245,245,245,1) 100%);
      background: linear-gradient(to right, rgba(245,245,245,0) 0%,rgba(245,245,245,1) 100%);
    }
  }

  &.-title {
    background-color: var(--colorQuaternary);
    color: var(--colorInverse);
    padding: 0 10px;
    margin-right: .5rem;
    min-width: 80px;
    height: 22px;
    font-size: .7rem;
    font-weight: 400;
    line-height: 1;
    border-radius: 20px;
    letter-spacing: .02em;
    white-space: nowrap;
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    max-width: 100%;

    @media (min-width: 992px) {
      height: 29px;
      font-size: .85rem;
      padding: 0 18px;
      min-width: 120px;
    }

    @media (min-width: 1600px) {
      font-size: .8rem;
    }

    span {
      display: block;
      width: 100%;
      text-align: center;
    }
  }

  &.-abstract {
    font-size: .9rem;
    line-height: 1.3rem;
    color: var(--colorBase);

    @media (min-width: 1600px) {
      font-size: .8rem;
    }

    &, p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical; 
      text-overflow: -o-ellipsis-lastline;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &.-link {
    text-decoration: none;
    font-weight: 400;
    color: var(--colorBase);
    font-size: .95rem;
    line-height: 1.4rem;
    padding: 2px 12px;
    position: absolute;
    right: 19px;
    bottom: 17px;
    display: block;
    background: -webkit-linear-gradient(left, rgba(245,245,245,0) 0%,rgba(245,245,245,1) 10%);
    background: linear-gradient(to right, rgba(245,245,245,0) 0%,rgba(245,245,245,1) 10%);

    @media (min-width: 992px) {
      right: 30px;
      bottom: 37px;
    }
  
    @media (min-width: 1600px) {
      bottom: 38px;
    }

    &:hover {
    
      b {
        color: var(--colorPrimary);
      }
    }

    b {
      color: var(--colorBase);
      font-weight: 600;
      transition: all .35s ease;
    }
  }
}