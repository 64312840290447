/**
 * components/_windbox.scss
 */

.windbox {

  &.-wrap {
    position: relative;
    overflow: hidden;
    padding-top: 2rem;
    padding-bottom: 2.8rem;
  }

  &.-item {
    //height: 4.375rem;
    background-color: var(--colorInverse);
    margin: 10px 0;
    
    @media (min-width: 992px) {
      //height: 6.25rem;
    }

    @media (min-width: 1600px) {
      //height: 5rem;
    }
  }

  &.-head {

  }

  &.-button {
    display: flex;
    height: 4.375rem;
    padding: 1.25rem;
    outline: 0;
    cursor: pointer;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 992px) {
      height: 5rem;
      padding: 1.5rem;
    }

    &[aria-disabled=true], &[aria-expanded=true] {

      .-icon {
    
        .icon {
          background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 19 10' viewBox='0 0 19 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m9.5 0-.77.67-8.73 7.55 1.55 1.78 7.95-6.88 7.96 6.88 1.54-1.78-8.73-7.55z' fill='%23707070' /%3E%3C/svg%3E");
        }
      }
    }
  }

  &.-button-target {
    display: flex;
    flex-flow: row;
    align-items: center;
  }

  &.-position {
    width: 1.875rem;
    height: 1.875rem;
    background-color: var(--colorLight);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #B9B9B9;

    @media (min-width: 992px) {
      width: 2rem;
      height: 2rem;
    }

    &.-finished {
      background-color: var(--colorSupport);
      svg {
        fill: #ffffff;
      }
    }
  }

  &.-title {
    font-size: 1.125rem;
    padding-left: 1.25rem;
    line-height: 1.25;
    
    @media (min-width: 992px) {
      font-size: 1.3rem;
      padding-left: 2.9rem;
    }
  }

  &.-icon {
    
    .icon {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 19.003 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m10 9.5-.671-.774-7.546-8.726-1.783 1.548 6.875 7.952-6.875 7.955 1.783 1.545 7.546-8.728z' transform='matrix(0 1 -1 0 19.003 0)' fill='%23707070' /%3E%3C/svg%3E");
      width: 18px;
      height: 8px;

      @media (min-width: 992px) {
        width: 20px;
        height: 10px;
      }
    }
  }

  &.-panel {

  }

  &.-content {
    padding: 1.25rem;
    max-width: 596px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 992px) {
      padding: 1.5rem;
    }
  }
}