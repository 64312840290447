/**
 * objects/_back.scss
 */

.back {

  &.-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}